import React, { Component, useEffect, useState } from "react";
import {
  RecentlyCreatedAssetsData,
  AssetData,
  CreateButtons,
} from "../../data";
import DropdownModal from "../subcomponents/dropdownModal";
import "./loan.scss";
import DropdownIco from "../../assets/others/down_button.svg";
import Table from "../subcomponents/table";
import Modal from "../subcomponents/modal";
import {useWeb3React} from "@web3-react/core";
import {isAddress} from "@ethersproject/address";
import ABI from "../../constants/abi/Conjure.json";
import COLL_ABI from "../../constants/abi/EtherCollateral.json";
import {Contract} from "@ethersproject/contracts";
import {addToast} from "../../hooks/useToast";
import {DateTime} from "luxon";
import {formatEther, parseEther} from "@ethersproject/units";
import {format_friendly} from "../../lib/utils";
import getReceipt from "../../lib/getReceipt";
import {BigNumber} from '@ethersproject/bignumber';

const SectionBlock = ({ data, onClick, onChange, placeholder }) => {
  let style = { color: "#00B6C1" };
  if (data.param === "deposit" || data.param === 'withdraw' || data.param === 'repay') {
    style = { background: "#00B6C1", color: "white" };
  }

  return (
    <div className="section-block">
      <div className="item item2">
        <input
          value={data.value}
          placeholder={placeholder}
          type="number"
          onChange={(e) => onChange({ [data.param]: e.target.value })}
        />
      </div>
      <div
        className="item item2 item3"
        style={style}
        onClick={() => onClick(data)}
      >
        {data.param}
      </div>
    </div>
  );
};

const InputBlock = ({ data, onClick, onChange, index, name }) => {
  return (
    <div className="input-block">
      <div>{data.name}</div>
      <div className="item item2">
        <input
          disabled
          value={data.value}
          // placeholder="Select Price Feed"
          // onChange={(e) => onChange({ [data.param]: e.target.value })}
        />
      </div>
    </div>
  );
};

const LoanModal = ({ clickedRow, setModal }) => {
  const [inputs, setInputs] = useState({
    deposit: "",
    withdraw: "",
    repay: "",
  });
  const [loanData, setloanData] = useState({
    asset: "",
    loanAmount: "",
    cRatio: "",
    uniqueId: "",
    collateral: "",
    openedAT: "",
  });

  const {account, library} = useWeb3React();

  console.log('in')
  console.log(clickedRow)

  const InputBoxes = [
    { name: "Asset", param: "asset" },
    { name: "Loan Amount", param: "loanAmount" },
    { name: "c - Ratio", param: "cRatio" },
    { name: "Unique ID", param: "uniqueId" },
    { name: "Collateral", param: "collateral" },
    { name: "Opened at", param: "openedAT" },
    { name: "Current Price", param: "currentPrice" },
  ];

  useEffect(() => {
    console.log('ok')
    getData()
  },[account,clickedRow ]);

  async function getData() {
    const colladdress = clickedRow.Address
    const loanID = clickedRow.LoanID

    console.log(loanID)

    const coll_contract = isAddress(colladdress) && !!COLL_ABI && !!library ? new Contract(colladdress, COLL_ABI, library) : undefined;

    const { hide: hidePending } = addToast({
      body: "Checking Loan Details",
      type: "loading",
      hideAfter: 0,
    });

    try {

      const conjure = await coll_contract.arbasset()
      const conjure_contract = isAddress(conjure) && !!ABI && !!library ? new Contract(conjure, ABI, library) : undefined;

      const symbol = await conjure_contract.symbol();
      const name = await conjure_contract.name();
      const lastprice = await conjure_contract.getLatestPrice();
      const lastpricetime = await conjure_contract.getLatestPriceTime();

      const loan_info = await coll_contract.getLoan(account, loanID)

      const loanAmount = loan_info.loanAmount
      const collateralAmount = loan_info.collateralAmount
      const timeCreated = loan_info.timeCreated

      let cratio = BigNumber.from("0")

      try {
        cratio = await coll_contract.getLoanCollateralRatio(account, loanID)
      } catch {}

      let enddate = DateTime.fromSeconds(timeCreated.toNumber())

      const loan_data = {
        asset: name,
        loanAmount: formatEther(loanAmount) + " " + symbol,
        cRatio: format_friendly(cratio.mul(100),4) + "%",
        uniqueId: loanID,
        collateral: formatEther(collateralAmount) +" " + "ETH",
        openedAT: enddate.toLocaleString(DateTime.DATETIME_SHORT),
        currentPrice: "$" + formatEther(lastprice)
      }

      setloanData(loan_data)

      hidePending();
      addToast({body: "Loading successful", type: "success"});

    } catch (e) {
      console.log(e)

      hidePending();
      addToast({body: "There was an error loading the loan", type: "error"});
    }
  }

  async function closeloan()
  {
    const collateral_contract = isAddress(clickedRow.Address) && !!COLL_ABI && !!library ? new Contract(clickedRow.Address, COLL_ABI, library.getSigner(account)) : undefined;

    try {
      const {hash} = await collateral_contract.closeLoan(clickedRow.LoanID);
      await getReceipt(hash, library);
      await getData();
      setModal(false)
    } catch (e) {
      addToast({body: e.message, type: "error"});
    }
  }

  async function repayloan()
  {

    const repay_loan = parseEther(inputs.repay);
    const collateral_contract = isAddress(clickedRow.Address) && !!COLL_ABI && !!library ? new Contract(clickedRow.Address, COLL_ABI, library.getSigner(account)) : undefined;

    try {
      const {hash} = await collateral_contract.repayLoan(account, clickedRow.LoanID, repay_loan);
      await getReceipt(hash, library);
      await getData();
      setInputs({
        deposit: "",
        withdraw: "",
        repay: "",
      })
    } catch (e) {
      addToast({body: e.message, type: "error"});
    }
  }

  async function withdraw()
  {

    if (inputs.withdraw <= 0)
    {
      addToast({body:"Please enter a valid amount to withdraw (greater 0)", type: "error"});
      return;
    }

    const collateral_contract = isAddress(clickedRow.Address) && !!COLL_ABI && !!library ? new Contract(clickedRow.Address, COLL_ABI, library.getSigner(account)) : undefined;
    const withdraw_eth = parseEther(inputs.withdraw);

    try {
      const {hash} = await collateral_contract.withdrawCollateral(clickedRow.LoanID, withdraw_eth);
      await getReceipt(hash, library);
      await getData();
      setInputs({
        deposit: "",
        withdraw: "",
        repay: "",
      })
    } catch (e) {
      addToast({body: e.message, type: "error"});
    }
  }

  async function deposit()
  {

    if (inputs.deposit <= 0)
    {
      addToast({body:"Please enter a valid amount to deposit (greater 0)", type: "error"});
      return;
    }

    const collateral_contract = isAddress(clickedRow.Address) && !!COLL_ABI && !!library ? new Contract(clickedRow.Address, COLL_ABI, library.getSigner(account)) : undefined;
    const depositeth = parseEther(inputs.deposit);

    const trans_obj = {
      // Required unless deploying a contract (in which case omit)
      //gasLimit: 300000,        // the maximum gas this transaction may spend
      value: depositeth           // the amount (in wei) this transaction is sending
    }

    try {
      const {hash} = await collateral_contract.depositCollateral(account, clickedRow.LoanID, trans_obj);
      await getReceipt(hash, library);
      await getData();
      setInputs({
        deposit: "",
        withdraw: "",
        repay: "",
      })

    } catch (e) {
      addToast({body: e.message, type: "error"});
    }
  }


  const onClickSection = () => {};

  const onChange = (data) => {
    setInputs({ ...inputs, ...data });
  };

  return (
    <div className="loan-modal do-center">
      <div className="section">
        <div className="heading">Your open loans</div>
        <div className="input-details">
          {InputBoxes.map((i, k) => (
            <InputBlock
              // onChange={onChange}
              data={{ ...i, value: loanData[i.param] }}
            />
          ))}
        </div>
        <div className="section-a do-between">
          <SectionBlock
            data={{ value: inputs.deposit, param: "deposit" }}
            placeholder={"Deposit ETH"}
            onChange={onChange}
            onClick={deposit}
          />
          <SectionBlock
            data={{ value: inputs.withdraw, param: "withdraw" }}
            onChange={onChange}
            onClick={withdraw}
            placeholder={"Withdraw ETH"}
          />
          <SectionBlock
            data={{ value: inputs.repay, param: "repay" }}
            onChange={onChange}
            onClick={repayloan}
            placeholder={"Repay " + clickedRow["Name"]}
          />
        </div>
        <div className="connect-button big-button" onClick={closeloan}>close loan</div>
      </div>
    </div>
  );
};
export default LoanModal;
