import React, { Component, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import CreateIco from "../assets/main/CreateIcon.svg";
import HomeIco from "../assets/main/HomeIcon.svg";
import LoanIco from "../assets/main/LoanIcon.svg";
import ManageIco from "../assets/main/ManageIcon.svg";
import StakeIco from "../assets/main/Stake.svg";
import AppIco from "../assets/main/conjure.png";
import DefiIco from "../assets/main/DFP _D_ logo.svg";
import NotifIco from "../assets/others/notification.svg";
import { ReactComponent as DropdownIco } from "../assets/others/down_button.svg";

import "./main.scss";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import moment from "moment";
import Home from "./Home/home";
import Create from "./Create/create";
import Stake from "./Stake/stake";
import Loan from "./Loan/loan";
import Manage from "./Manage/manage";
import Airdrop from "./Airdrop/airdrop";
import Details from "./Stake/details";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import ClickOutside from "./subcomponents/clickOutside";
import NotificationModal from "./subcomponents/notificationModal";

import { useWeb3React } from "@web3-react/core";
import { useWalletModalToggle } from "./WalletModal/state";

import WalletModal from "./WalletModal";

const Comp = ({ source, title, active, onClick }) => {
  let history = useHistory();

  return (
    <div
      onClick={() => {
        if (onClick) onClick(false);
        history.push(`/main/${title}`);
      }}
      className="leftbar-item"
      style={{
        borderRight: active == title ? "6px solid #FFC33090" : "initial",
        background: active == title ? "#f0ac001f" : "initial",
      }}
    >
      <div className="do-center">
        <img src={source} />
      </div>
      <div>{title}</div>
    </div>
  );
};

const LeftBar = ({ active }) => {
  return (
    <div className="leftbar">
      <div className="app-icon">
        <a href="/">
        <img src={AppIco} />
        </a>
      </div>
      <Comp source={HomeIco} title={"home"} active={active} />

      <Comp source={CreateIco} title={"create"} active={active} />

      <Comp source={StakeIco} title={"stake"} active={active} />
      <Comp source={ManageIco} title={"manage"} active={active} />

      <Comp source={LoanIco} title={"loan"} active={active} />
      <a href="https://docs.conjure.finance/" target="_blank"><Comp title={"Docs"} /></a>
      <a href="https://docs.oof.conjure.finance/" target="_blank"><Comp title={"OOF"} /></a>
      <a href="https://github.com/ConjureFi" target="_blank"><Comp title={"Github"} /></a>
      <a href="https://twitter.com/ConjureFi" target="_blank"><Comp title={"Twitter"} /></a>
      <a href="https://discord.gg/qeYPBYH5qB" target="_blank"><Comp title={"Discord"} /></a>
      <a href="https://t.me/ConjureFinanceDiscussion" target="_blank"><Comp title={"Telegram"} /></a>
      <a href="https://forum.conjure.finance" target="_blank"><Comp title={"Forum"} /></a>
      <a href="https://defipulse.com/" target="_blank"><Comp title={"Defipulse"} source={DefiIco} /></a>
    </div>
  );
};

const TopBarMobile = ({ active }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <ClickOutside setOpened={setNavExpanded}>
      <div className="topbar">
        <Navbar
          expand="lg"
          onToggle={(e) => setNavExpanded(e)}
          expanded={navExpanded}
        >
          <div className="app-icon">
            <a href="/">
            <img src={AppIco} />
            </a>
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Comp
                source={HomeIco}
                title={"home"}
                active={active}
                onClick={setNavExpanded}
              />

              <Comp
                source={CreateIco}
                title={"create"}
                active={active}
                onClick={setNavExpanded}
              />

              <Comp
                source={StakeIco}
                title={"stake"}
                active={active}
                onClick={setNavExpanded}
              />
              <Comp
                source={ManageIco}
                title={"manage"}
                active={active}
                onClick={setNavExpanded}
              />

              <Comp
                source={LoanIco}
                title={"loan"}
                active={active}
                onClick={setNavExpanded}
              />
              <a href="https://docs.conjure.finance/" target="_blank" style={{color: "white"}}><Comp title={"Docs"} /></a>
              <a href="https://docs.oof.conjure.finance/" target="_blank" style={{color: "white"}}><Comp title={"OOF"} /></a>
              <a href="https://github.com/ConjureFi" target="_blank" style={{color: "white"}}><Comp title={"Github"} /></a>
              <a href="https://twitter.com/ConjureFi" target="_blank" style={{color: "white"}}><Comp title={"Twitter"} /></a>
              <a href="https://discord.gg/qeYPBYH5qB" target="_blank" style={{color: "white"}}><Comp title={"Discord"} /></a>
              <a href="https://t.me/ConjureFinanceDiscussion" target="_blank" style={{color: "white"}}><Comp title={"Telegram"} /></a>
              <a href="https://forum.conjure.finance" target="_blank" style={{color: "white"}}><Comp title={"Forum"} /></a>
              <a href="https://defipulse.com/" target="_blank" style={{color: "white"}}>
                  <Comp title={"Defipulse"} />
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </ClickOutside>
  );
};

const Notification = ({ notifications, setIsOpened }) => {
  let history = useHistory();

  return (
    <div className="notification-modal">
      {notifications.map((i, k) => {
        return (
          <div
            key={k}
            onClick={() => {
              history.push("/main/manage/asset/" + i.id);
              setIsOpened(false);
            }}
            className="option"
          >
            {i.description}
            {/* <img
              src={DropdownIco}
              width={10}
              style={{ transform: "rotate(270deg)" }}
            /> */}
            <DropdownIco fill={"white"} />
          </div>
        );
      })}
    </div>
  );
};

const MainBar = () => {
  const [active, setActive] = useState("home");
  const [connected, setConnected] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const [notifications, setNotifications] = useState([
    {
      id: 13,
      description:
        "Your transaction is now confirmed and can see the details here",
    },
    {
      id: 13,
      description:
        "Your transaction is now confirmed and can see the details here",
    },
  ]);

  const { account } = useWeb3React();
  const toggleWalletModal = useWalletModalToggle();

  const location = useLocation();
  useEffect(() => {
    setActive(location.pathname.split("/")[2]);
  }, [location]);

  const connectWallet = () => {
    setConnected("idfhfhndjj");

    //after connected
    //add funtion for complte transaction
    // history.push("/main/loan");
  };

  return (
    <div className="mainblock">
      <div className={"top-header"}>
        {active == "home" ? (
          <div className="page-heading">
            <div
              style={{
                fontSize: "14px",
                color: "#ebb32fcc",
                marginBottom: "-9px",
              }}
            >
              Hello
            </div>
            <div>Welcome to Conjure!</div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="user-panel">
          {/* <div onClick={() => setIsOpened(!isOpened)}>
            <img src={NotifIco} />
            {notifications[0] && <span>{notifications.length}</span>}
          </div> */}

          <div onClick={toggleWalletModal} className="connect-button">
            <p className="px-1">
              {!!account ? "My Wallet" : "Unlock Wallet"}
            </p>
          </div>
          {/* {isOpened && (
            <div>
              <NotificationModal opened={isOpened} setOpened={setIsOpened}>
                <Notification
                  notifications={notifications}
                  setIsOpened={setIsOpened}
                />
              </NotificationModal>
            </div>
          )} */}
        </div>
      </div>

      <Switch>
        <Route exact path="/main" render={() => <Redirect to="/main/home" />} />

        <Route exact path="/main/home" component={Home} />
        <Route exact path="/main/home/:id" component={Home} />
        <Route exact path="/main/create" component={Create} />
        <Route exact path="/main/stake" component={Stake} />
        <Route
          exact
          path="/main/stake/details/:id/:status"
          component={Details}
        />

        <Route exact path="/main/loan" component={Loan} />
        <Route
          exact
          path="/main/manage"
          render={() => <Redirect to="/main/manage/table" />}
        />
        <Route exact path="/main/manage/user/:id" component={Manage} />
        <Route exact path="/main/manage/:id" component={Manage} />
        <Route exact path="/main/manage/asset/:id" component={Manage} />
        <Route exact path="/main/manage/owner/:id" component={Manage} />
        <Route exact path="/main/manage/table" component={Manage} />
        <Route exact path="/main/airdrop" component={Airdrop} />

        {/* <Route exact path="/main/clientDetails" component={ClientDetails} />
        <Route exact path="/main/payment" component={Payment} />
        <Route exact path="/main/newclient" component={NewClient} />
        <Route exact path="/main/list/restaurants" component={Restaurants} />
        <Route exact path="/main/list/restaurant/:id" component={Restaurant} /> */}
      </Switch>
    </div>
  );
};

const Main = () => {
  const [active, setActive] = useState("home");

  const location = useLocation();

  useEffect(() => {
    setActive(location.pathname.split("/")[2]);
  }, [location]);
  return (
    <div className="main">
      <LeftBar active={active} />
      <TopBarMobile active={active} />
      <MainBar />
      <WalletModal />
    </div>
  );
};

export default Main;
