import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./lib/getLibrary";
import {
  BrowserRouter as Router
} from "react-router-dom";
import Routes from "./routes";
import { ApolloProvider } from '@apollo/client/react';

import {ApolloClient, InMemoryCache} from "@apollo/client";

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/richance/conjure-loans',
  cache: new InMemoryCache()
});


function App() {
  return (
      <ApolloProvider client={client}>
      <Web3ReactProvider getLibrary={getLibrary}>
    <div className="App">
      <Router>
        <Routes />
      </Router>
    </div>
      </Web3ReactProvider>
      </ApolloProvider>
  );
}

export default App;
