import React, { FunctionComponent } from "react";
import ClickOutside from "./clickOutside";
import "./modal.scss";

const Modal = ({ children, show, styleName, toggleShow, style }) => {
  return show ? (
    <div className={"modalView"}>
      <div>
        <div style={style}>
          <div>
            <ClickOutside setOpened={toggleShow}>
              <div className="modalClass">{children}</div>
            </ClickOutside>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default Modal;
