import Ico from "./assets/others/Trending.svg";
import CurrencyIco from "./assets/others/LogoCircle@2x.png";
import BitcoinIco from "./assets/others/bitcoin-btc-logo.svg";
import EthereumIco from "./assets/others/ethIcon.svg";
import UniswapIco from "./assets/others/UniIcon.svg";
import ConjureIco from "./assets/others/ConjureIcon@2x.png";
import LitecoinIco from "./assets/others/litecoin-ltc-logo.svg";

export const MintData = {
  currentPrice: "$64,342.00",
  fixedRatio: "200%",
  mintFee: "1%",
};

export const TrendingAssetsData = [
  {
    ticker: "BTC",
    name: { icon: BitcoinIco, name: "Bitcoin" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "ETH",
    name: { icon: EthereumIco, name: "Ethereum" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "UNI",
    name: { icon: UniswapIco, name: "Uniswap" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "CNJ",
    name: { icon: ConjureIco, name: "Conjure" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
  {
    ticker: "LTC",
    name: { icon: LitecoinIco, name: "Litecoin" },
    price: "231$",
    supply: "231$",
    tvl: "231$",
    price_change: "231$",
    volume: "231$",
  },
];

export const RecentlyCreatedAssetsData = [
  {
    ticker: "BTC",
    name: { icon: BitcoinIco, name: "Bitcoin" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "ETH",
    name: { icon: EthereumIco, name: "Ethereum" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "UNI",
    name: { icon: UniswapIco, name: "Uniswap" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "CNJ",
    name: { icon: ConjureIco, name: "Conjure" },
    price: "231$",
    supply: "231$",
  },
  {
    ticker: "LTC",
    name: { icon: LitecoinIco, name: "Litecoin" },
    price: "231$",
    supply: "231$",
  },
];

export const LoanTableData = [
  {
    name: { icon: BitcoinIco, name: "Bitcoin" },

    symbol: "BTC",
    amount_borrowed: "11.0",
    collateral_ratio: "6%",
  },
  {
    name: { icon: EthereumIco, name: "Ethereum" },

    symbol: "ETH",
    amount_borrowed: "12.0",
    collateral_ratio: "8%",
  },
  {
    name: { icon: UniswapIco, name: "Uniswap" },

    symbol: "UNI",
    amount_borrowed: "55.0",
    collateral_ratio: "1%",
  },
  {
    name: { icon: ConjureIco, name: "Conjure" },

    symbol: "CNJ",
    amount_borrowed: "23.0",
    collateral_ratio: "5%",
  },
  {
    name: { icon: LitecoinIco, name: "Litecoin" },

    symbol: "LTC",
    amount_borrowed: "43.0",
    collateral_ratio: "7%",
  },
];

export const ManageTableData = [
  {
    event: "Burn",
    address: "0xgye32rsas3353fsss",
    amount: "10.0",
    time: "231$",
  },
  {
    event: "Mint",
    address: "B0xertewerrcd33r32asTC",
    amount: "13.0",
    time: "231$",
  },
  {
    event: "Burn",
    address: "0xe23hfg5sdh3jhjdf4",
    amount: "12.0",
    time: "231$",
  },
];

export const StakeData = [

  {
    icon: CurrencyIco,
    heading: "Synth USD (xUSD) - USDC UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xUSD",
    apy: "5%",
    id: 1,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0x88E6eDe4266E5B97DCF70e67D49173e12a97985C",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x118cc5a08bebc41695ecd1bb0d8bb60e68dd8d65",
    outputToken: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
  },
  {
    icon: CurrencyIco,
    heading: "Synth BTC (xBTC) - WBTC UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xBTC",
    apy: "5%",
    id: 2,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0xFc7b53024bbdB263dA0c1A0f4e255Aa3C169Df88",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0xb83534012b183746cffdfe6abba359cc2720d1cd",
    outputToken: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
  },
  {
    icon: CurrencyIco,
    heading: "CryptoCurrency Index (xCC) - WETH UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xCC",
    apy: "5%",
    id: 3,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0xcC8FC47494Fc01485720931A521478527078A65E",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x7b4d9e591c6324cbbb1355bc50a27892fd2af99c",
    outputToken: "ETH"
  },
  {
    icon: CurrencyIco,
    heading: "Synth Banana (xNANA) - USDC UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xCC",
    apy: "5%",
    id: 4,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0x056EbdC492A49C0E2913c7AC14C2C46dBD3759FB",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x13a1105d770c19f0bc7eaa63cb3f7b5b06f01966",
    outputToken: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
  },

  {
    icon: CurrencyIco,
    heading: "Conjure (CNJ)",
    rate: "1650 CNJ/DAY",
    total: "465,422 CNJ",
    status: "stake",
    staked: "145,346",
    univ2: "ETH - DEGENz",
    apy: "5%",
    id: 5,
    poolAddress: "0xc8857c35B6f33a1325143D491589cAE01B504f87",
    tokenAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
  },
  {
    icon: CurrencyIco,
    heading: "Synth USD (xUSD) - USDC UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xUSD",
    apy: "5%",
    id: 6,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0x3172B1fa9DB19172A3510c9DE7F1CB9AEC3e9fa1",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x1b3be37Ce83539802Cc63919937DDAe3dce8096c",
    outputToken: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48"
  },
  {
    icon: CurrencyIco,
    heading: "Synth BTC (xBTC) - WBTC UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xBTC",
    apy: "5%",
    id: 7,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0x933514619863680095c95C6bCE8F02D0153e198c",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x6d9bde2df9f8420372bb69c2d62349b5b21af561",
    outputToken: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599"
  },
  {
    icon: CurrencyIco,
    heading: "CryptoCurrency Index (xCC) - WETH UNI V2 LP",
    rate: "Pool Ended",
    total: "465,422 CNJ",
    status: "ended",
    staked: "145,346",
    univ2: "ETH - xCC",
    apy: "5%",
    id: 8,
    poolAddress: "0xbc4fc253666dc5dde5e15de3bd11deb4b5b2f2a9",
    tokenAddress: "0xe6b40cf07b373410b64da786dC07309A1062CDaf",
    rewardsAddress: "0x00a55375002f3cda400383f479e7cd57bad029a9",
    inputToken: "0x8a32f6051ae5798b9faebd30e951e4acc2a11015",
    outputToken: "ETH"
  },

];

export const AssetData = {
  assetType :{
  label: "Single Asset",
      id: 0,
   },
  inverseAsset: false,
  mintingFee: "0.0",
  cRatio: "120",
  divisor: "",
  name: "",
  symbol: ""
};

export const CreateButtons = [
  { name: "LINK", id: "link" },
  { name: "UNI", id: "uni" },
  { name: "CUSTOM", id: "custom" },
  { name: "CONJURE", id: "conjure" },
  { name: "DIA", id: "dia" },
  { name: "OOF", id: "oof" },
];

export const StakeDetails = {
  staked: "9654.22 CC10",
  univ2: "ETH - ORCL5",
  rate: "465,422 NDX/DAY",
  estReward: "0 NDX/DAY",
  poolWeight: "0 %",
  rewardsPool: "0Xe1de...Ba35",
  stakingEnds: "Mar 8, 2021, 2:32 PM UTC",
  stakedUniv2: "41,316.04",
  stakedCC10: "41,316.04",
  ndxPerDay: "9,374.99",
  totalNdx: "562,500",
  stakingToken: "CC10",
  earnedRewards: "0 NDX",
  balance: "1000000",
};

export const StakeDetailsParamsMapping = {
  staked: "Staked",
  univ2: "UNIV2",
  rate: "Rate",
  estReward: "Est Reward",
  poolWeight: "Pool Weight",
  rewardsPool: "Rewards Pool",
  stakingEnds: "Staking Ends",
  stakedUniv2: "STAKED Univ2",
  stakedCC10: "Staked Cc10",
  ndxPerDay: "NDX Per Day",
  totalNdx: "Total NDX",
  stakingToken: "staking Token",
  earnedRewards: "earned Rewards",
  balance: "balance",
};

export const AssetDetails = {
  assetBalance: "XEM",
  latestPrice: "200",
  recordedOn: "1.5%",
  assetsInCirculation: "2%",
  priceAtCreation: "2%",
};
