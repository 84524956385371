import React, { Component } from "react";
import TableIco from "../../assets/others/Trending.svg";
import "./table.scss";

const Table = ({ data, heading, onClick, icon, colors, className }) => {
  const headers = Object.keys(data[0]).map((i, k) => i.replace("_", " "));
  const params = Object.keys(data[0]);

  console.log(headers, params);

  return (
    <div className={`table-div ${className}`}>
      <div className="table-heading">
        <div>
          <img src={icon ? icon : TableIco} />
          <span>{heading}</span>
        </div>
        <div></div>
      </div>
      <div className="table-class">
        <table>
          <thead>
            <tr>
              {headers.map((i, k) => {
                return <th>{i}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((i, k) => {
              return (
                <tr
                  onClick={() => {
                    if (onClick) onClick(i, k);
                  }}
                  style={{ background: k % 2 == 0 ? "#00b7c11e" : "initial" }}
                >
                  {params.map((m, n) => {
                    if (m == "name" && typeof i[m] == "object") {
                      return (
                        <td style={{}}>
                          {i[m].name}
                        </td>
                      );
                    } else
                      return (
                        <td style={{ color: colors && colors[m] }}>{i[m]}</td>
                      );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Table;
