import React, {Component, useEffect, useState} from "react";
import {MintData, TrendingAssetsData} from "../../data";
import DropdownModal from "../subcomponents/dropdownModal";
import "./home.scss";
import DropdownIco from "../../assets/others/down_button.svg";
import Table from "../subcomponents/table";
import {useHistory} from "react-router";
import TrendingIco from "../../assets/others/Trending.svg";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";
import ABI from "../../constants/abi/Conjure.json";
import COLLATERAL_ABI from "../../constants/abi/EtherCollateral.json";
import {useWeb3React} from "@web3-react/core";
import {AlchemyProvider, InfuraProvider} from "@ethersproject/providers";
import {INFURA_ID, INFURA_NETWORK} from "../../constants";
import {addToast, PendingTx, SuccessfulTx, PendingIPFS, SuccessfulIPFS} from "../../hooks/useToast";
import {BigNumber} from '@ethersproject/bignumber';
import {formatEther, parseEther, formatUnits} from "@ethersproject/units";
import getReceipt from "../../lib/getReceipt";
import {format_friendly, getEtherscanLink} from "../../lib/utils";
import {useLocation} from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import LINK from "../../constants/abi/chainlink.json";

const selectAssetNames = [
    {
        label: "xUSD",
        symbol: "xUSD",
        address: "0x118cc5a08bebc41695ecd1bb0d8bb60e68dd8d65",
    },
    {
        label: "xCC",
        symbol: "xCC",
        address: "0x7b4d9e591c6324cbbb1355bc50a27892fd2af99c",
    },
    {
        label: "xBTC",
        symbol: "xBTC",
        address: "0xb83534012b183746cffdfe6abba359cc2720d1cd",
    },
    {
        label: "xNANA",
        symbol: "xNANA",
        address: "0x13a1105d770c19f0bc7eaa63cb3f7b5b06f01966",
    },
    {
        label: "xEUR",
        symbol: "xEUR",
        address: "0xacaf7399e3140a9cef8cc5e1fc82303747c1deed",
    },
    {
        label: "xGBP",
        symbol: "xGBP",
        address: "0x55a5d6b9a15985dafe4c952a730a83a35181bdeb",
    },
    {
        label: "Custom",
        symbol: "Custom",
        address: "0x"
    },
];

const Home = () => {
    const [dropdownA, setDropdownA] = useState(false);
    const [dropdownB, setDropdownB] = useState(false);
    const [deposit, setDeposit] = useState(0);
    const [mint, setMint] = useState({
        value: 0,
        selected: selectAssetNames[0].label,
    });
    const [customaddress, setcustomaddress] = useState("");
    const [iscustom, setiscustom] = useState(false)
    const [customchecked, setcustomchecked] = useState(false)

    const [mintdetails, setMintDetails] = useState(MintData);
    const [connected, setConnected] = useState(false);
    let history = useHistory();

    const dropdownAChange = (data) => {
        setDeposit({...deposit, selected: data.label});
    };

    const {account, library} = useWeb3React();
    const library_infura = new AlchemyProvider(INFURA_NETWORK, INFURA_ID);

    //loan fields of state
    const[collratio, setcollratio] = useState(BigNumber.from(0));
    const[tokensymbol, settokensymbol] = useState("0");
    const[ethbalance, setethbalance] = useState(BigNumber.from(0));
    const[issueratio, setissueratio] = useState(BigNumber.from(0));
    const[issuefee, setissuefee] = useState(BigNumber.from(0));
    const[mincoll, setmincoll] = useState(BigNumber.from(0));
    const[totalissue, settotalissue] = useState(BigNumber.from(0));
    const[totalloans, settotalloans] = useState(BigNumber.from(0));
    const[openloans, setopenloans] = useState(BigNumber.from(0));
    const [lastetherprice, setlastetherprice] = useState(BigNumber.from(0));

    const [loanarray, setloanarray] = useState([]);
    const [loanratios, setloanrations] = useState([]);
    const [collarray, setcollarrayy] = useState([]);
    const [conjurearray, setconjurearray] = useState([]);
    const [onlyonce, setonlyonce] = useState(true)

    const [withdrawamount, setwithdrawamount] = useState(0);
    const [depositamount, setdepositamount] = useState(0);
    const [repayamount, setrepayamount] = useState(0);

    const[cratio, setcratio] = useState(BigNumber.from(0));
    const [collamount, setcollamount] = useState('');
    const [loanamount, setloanamount] = useState('');
    const [ethusdprice, setethusdprice] = useState(0)

    // conjure states

    const [lastPrice, setLastPrice] = useState(BigNumber.from(0));
    const [lastTime, setLastTime] = useState(0);

    const [showdetails, setshowdetails] = useState(false)

    // collateral
    const [collateralAddress, setCollateralAddress] = useState('');
    const [currentAddress, setCurrentAddress] = useState('');

    const [first_router, set_first_router] = useState(0);
    const [first_autocheck, set_first_autocheck] = useState(0);
    const [check_query, set_check_query] = useState(0);
    const location = useLocation();

    const EXCHANGE_RATES = gql`
        query GetExchangeRates {
            statistics(first: 1000) {
                id
                totalNetCollateral
            }
        }
    `;

    const { loading, error, data  } = useQuery(EXCHANGE_RATES, {
        pollInterval: 500,
    });

    // effect hook for updating data
    useEffect(() => {

        if (first_router === 0)
        {
            set_first_router(1);
            {
                const splitter = location.pathname.split("/")[3];
                console.log(splitter);

                try
                {
                    let my_address = splitter;
                    console.log(my_address);

                    if (my_address !== undefined && my_address !== 'table')
                    {
                        //setManageInput(my_address);
                        set_check_query(1);
                        setcustomchecked(true)
                        setshowdetails(true)
                        setiscustom(true)
                        setMint({...mint, selected: "Custom"});
                        setcustomaddress(my_address)
                        checkConjureDetails(my_address)
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }
        }
    }, []);

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }


    function getUSDValue() {
        const ethvalue = formatEther(data['statistics'][0].totalNetCollateral) * formatUnits(ethusdprice,8)
        return formatNumber(parseInt(ethvalue))
    }

    async function checkConjureDetails(callingaddress) {

        console.log(customaddress)


        const conjure_contract = isAddress(callingaddress) && !!ABI && !!library_infura ? new Contract(callingaddress, ABI, library_infura) : undefined;

        const { hide: hidePending } = addToast({
            body: "Loading Asset Details",
            type: "loading",
            hideAfter: 0,
        });


        try {
            const collateral = await  conjure_contract._collateralContract();
            setCollateralAddress(collateral)
            setCurrentAddress(callingaddress)
            const symbol = await conjure_contract.symbol();
            const lastprice = await conjure_contract.getLatestPrice();
            const ethprice = await conjure_contract.getLatestETHUSDPrice();

            settokensymbol(symbol);
            setLastPrice(lastprice);
            setlastetherprice(ethprice)

            // get collateral contract infos
            const collateral_contract = isAddress(collateral) && !!COLLATERAL_ABI && !!library_infura ? new Contract(collateral, COLLATERAL_ABI, library_infura) : undefined;
            console.log(collateral_contract);

            const info = await collateral_contract.getContractInfo();

            setcollratio(info._collateralizationRatio);
            setissueratio(info._issuanceRatio)
            setissuefee(info._issueFeeRate)

            hidePending();
            addToast({body: "Loading successful", type: "success"});


        } catch (e) {
            console.log(e)

            hidePending();
            addToast({body:"There was an error loading the asset details", type: "error"});
            return false;
        }

        return true;
    }

    const customselection = async () => {

        let checkcustomer = await checkConjureDetails(customaddress)

        if (checkcustomer === true) {
            setcustomchecked(true)
            setshowdetails(checkcustomer)
        }
        else
        {
            setcustomchecked(false)
            setshowdetails(checkcustomer)
        }

    };



    const dropdownBChange = async (data) => {
        setcustomchecked(false)
        setshowdetails(false)

        if (data.label === "Custom") {
            setiscustom(true)
        }
        else {
            setiscustom(false)
            let checker = await checkConjureDetails(data.address)
            setshowdetails(checker)
        }

        setMint({...mint, selected: data.label});
    };

    const mintAsset = async () => {
        if (!account)
        {
            addToast({body:"Please Connect your Wallet before borrowing an asset", type: "error"});
            return;
        }

        if (loanamount <= 0)
        {
            addToast({body:"Please enter a loan amount greater than 0", type: "error"});
            return;
        }

        if (collamount < 0.05)
        {
            addToast({body:"Minimum Collateral Amount is 0.05 ETH", type: "error"});
            return;
        }

        const collateral_contract = isAddress(collateralAddress) && !!COLLATERAL_ABI && !!library ? new Contract(collateralAddress, COLLATERAL_ABI, library.getSigner(account)) : undefined;

        console.log('here')
        const loan_eth = parseEther(loanamount);

        const ethcoll = parseEther(collamount);
        const mintingfee = ethcoll.div(10000).mul(issuefee);

        console.log(ethcoll)
        console.log(mintingfee)
        console.log(ethcoll.add(mintingfee) )

        console.log('here2')
        const trans_obj = {
            value: ethcoll.add(mintingfee)         // the amount (in wei) this transaction is sending
        }

        try {
            const {hash} = await collateral_contract.openLoan(loan_eth, trans_obj);
            await getReceipt(hash, library);
            history.push("/main/stake");
        } catch (e) {
            addToast({body: e.message, type: "error"});
        }

    };

    function multiplyDecimal(x, y)
    {
        let precisionUnit = BigNumber.from("1000000000000000000");
        /* Divide by UNIT to remove the extra factor introduced by the product. */
        return x.mul(y).div(precisionUnit);
    }

    function divideDecimal(x, y){
        /* Reintroduce the UNIT factor that will be divided out by y. */
        let precisionUnit = BigNumber.from("1000000000000000000");
        return x.mul(precisionUnit).div(y);
    }

    function handleChangeCollAmount(event) {
        const values = event.target.value;
        setcollamount(values);

        if (isNaN(values) || values === "")
        {
            setcollamount("")
        }
        else
        {
            if (values > 0 && loanamount >0)
            {
                let loaninput = parseEther(loanamount);
                let collinput = parseEther(values);

                console.log(loaninput)
                console.log(collinput)
                console.log(lastetherprice)
                console.log(lastPrice)

                let mul1 = multiplyDecimal(loaninput, lastPrice)
                let mul2 = multiplyDecimal(collinput, lastetherprice)
                let div1 = divideDecimal(mul2,mul1)


                console.log(mul1)
                console.log(mul2)
                console.log(div1)

                setcratio(div1)
            }

        }
    }

    function handleChangeLoanAmount(event) {
        const values = event.target.value;
        setMint({...mint, value: values})
        setloanamount(values);

        if (isNaN(values) || values === "")
        {
            setloanamount("")
        }
        else
        {
            if (values > 0 && collamount >0) {
                let loaninput = parseEther(values);
                let collinput = parseEther(collamount);

                console.log(loaninput)
                console.log(collinput)
                console.log(lastetherprice)
                console.log(lastPrice)

                let mul1 = multiplyDecimal(loaninput, lastPrice)
                let mul2 = multiplyDecimal(collinput, lastetherprice)
                let div1 = divideDecimal(mul2,mul1)


                console.log(mul1)
                console.log(mul2)
                console.log(div1)

                setcratio(div1)
            }

        }
    }

    function ondetailclick() {
        console.log(currentAddress)
        history.push({pathname: "/main/manage/" + currentAddress });
    }

    // load initial adata once
    useEffect(() => {

        async function loaddetails()
        {
            let checker = await checkConjureDetails("0x118cc5a08bebc41695ecd1bb0d8bb60e68dd8d65")
            setshowdetails(checker)

            const linkcontract =
                isAddress("0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419") && !!LINK && !!library_infura
                    ? new Contract("0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419", LINK, library_infura)
                    : undefined;

            const ethusdpricetemp = await linkcontract.latestAnswer()
            setethusdprice(ethusdpricetemp)
        }

        if (onlyonce === true)
        {
            setonlyonce(false)
            loaddetails();
        }

    });


    return (
        <div className="home">
            { !loading && !error && ethusdprice !== 0 ?
                <p>Total Value Locked: <b>${getUSDValue()}</b></p>
            :

            ""}

            <div className="do-center">
                <div className="mint">
                    <div className="header do-center">borrow</div>
                    <div className="item">
                        <input
                            placeholder="DEPOSIT"
                            type="text"

                            value={collamount}
                            style={{width: "100%", textAlign: "center"}}
                            onChange={e => handleChangeCollAmount(e)}
                        />
                        <div
                            style={{textAlign: "right"}}
                        >
                            <span>ETH</span>
                            <span
                                style={{
                                    width: "25px",
                                    height: "10px",
                                    display: "inline-block",
                                }}
                            ></span>
                        </div>
                    </div>
                    <div className="item">

                        <input
                            placeholder="BORROW"
                            type="text"
                            value={loanamount}
                            style={{width: "100%", textAlign: "center"}}
                            onChange={e => handleChangeLoanAmount(e)}
                        />
                        <div
                            onClick={() => setDropdownB(true)}
                            style={{cursor: "pointer", textAlign: "right"}}
                        >
                            <span>{mint.selected}</span>
                            <img src={DropdownIco}/>
                        </div>
                        {dropdownB && (
                            <DropdownModal
                                options={selectAssetNames}
                                opened={dropdownB}
                                onChange={dropdownBChange}
                                setOpened={setDropdownB}
                            />
                        )}
                    </div>

                    { iscustom ?
                        <div className="item">
                            <input
                                style={{width: "100%", textAlign: "center"}}
                                placeholder="ADDRESS"
                                value={customaddress}
                                onChange={(e) =>
                                    setcustomaddress(e.target.value)
                                }
                            />
                        </div>
                    :
                    ""
                    }

                    {iscustom ?
                        <div onClick={customselection} className="connect-button">
                            Load Custom Asset
                        </div>
                    :
                    ""
                    }

                    {(iscustom && customchecked === false) ?
                    ""

                    :
                        showdetails === true ?

                        <div>
                            <div className="details do-between">
                                <span>Current Price {tokensymbol}</span>
                                <span>${formatEther(lastPrice)}</span>
                            </div>
                            <div className="details do-between">
                                <span>Fixed Collateral Ratio</span>
                                <span>{formatEther(collratio)}%</span>
                            </div>
                            <div className="details do-between">
                                <span>Minting Fee</span>
                                <span>{issuefee.toNumber()/100}%</span>
                            </div>
                            <div className="details do-between">
                                <span>Your Borrowing C-Ratio</span>
                                <span>{format_friendly(cratio.mul(100),4)}%</span>
                            </div>
                            <div className="details do-between">
                                <span></span>
                                <span onClick={ondetailclick} style={{cursor: "pointer"}}>More Info</span>
                            </div>
                        </div>
                            :
                            ""
                    }

                    {(iscustom && customchecked === false) ?
                        ""
                        :
                        showdetails === true ?

                        <div onClick={mintAsset} className="connect-button">
                            Borrow
                        </div>
                            :
                            ""
                    }
                    {(iscustom && customchecked === false) ?
                        ""
                        :
                        showdetails === true ?
                            <div  onClick={() => {
                                window.open(`https://app.uniswap.org/#/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=${currentAddress}`);
                            }} className="connect-button">
                                Trade
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        </div>
    );
};

export default Home;
