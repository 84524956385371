import React, {useState} from "react";
import "./create.scss";
import Table from "../subcomponents/table";
import Modal from "../subcomponents/modal";
import CreateModal from "./createModal";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";
import {InfuraProvider, AlchemyProvider} from "@ethersproject/providers";
import RecentIco from "../../assets/others/RecentIcon.svg";
import CONJURE_FACTORY_ABI from "../../constants/abi/ConjureFactory.json";
import ABI from "../../constants/abi/Conjure.json";
import {Interface} from "@ethersproject/abi";
import {DateTime} from "luxon";
import {
    CONJURE_FACTORY_ADDRESS,
    INFURA_ID,
    INFURA_NETWORK,
} from "../../constants";
import {useEffect} from 'react';
import {formatEther} from "@ethersproject/units";
import {useWeb3React} from "@web3-react/core";
import {addToast} from "../../hooks/useToast";
import { useHistory } from "react-router";

import {gql, useQuery} from "@apollo/client";

const Create = () => {
    const [modal, setModal] = useState(false);
    const library_infura = new AlchemyProvider(INFURA_NETWORK, INFURA_ID);
    const {account} = useWeb3React();
    let history = useHistory();

    const [tableData, setTableData] = useState(
        [
        {
        ticker: "",
        name: "Table is Loading" ,
        price: "",
        supply: "",
    }
    ])

    const EXCHANGE_RATES = gql`
        query GetExchangeRates {
            conjureAssets(orderBy: created, orderDirection: asc) {
                id
                address
                collateralAddress
                lastPrice
                created
                name
                symbol
            }
        }
    `;

    const { loading, error, data } = useQuery(EXCHANGE_RATES, {
        pollInterval: 500,
    });

    const connectWallet = () => {

        if (!account) {
            addToast({body: "Please unlock your wallet before creating a Conjure Asset", type: "info"});
            return;
        }

        setModal(true);
    };

    async function getConjureAssets() {
        if (error) return;

        console.log(data)

        let i;
        let temp_conj_info = []

        for (i=0; i < data['conjureAssets'].length;i++) {

            let enddate = DateTime.fromSeconds(parseInt(data['conjureAssets'][i].created))
            temp_conj_info.push({
                ticker: data['conjureAssets'][i].symbol,
                name: data['conjureAssets'][i].name,
                price: "$" + formatEther(data['conjureAssets'][i].lastPrice),
                createtime: enddate.toLocaleString(DateTime.DATETIME_SHORT),
                address: data['conjureAssets'][i].address
            });

        }

        if (temp_conj_info.length === 0) {
            temp_conj_info.push(
                {
                    ticker: "",
                    name: "Currently no assets recorded" ,
                    price: "",
                    supply: "",
                }
            )
        }

        console.log(temp_conj_info);

        if (temp_conj_info.length >5) {
            setTableData(temp_conj_info.reverse().slice(0,5))
        }
        else {
            setTableData(temp_conj_info.reverse())
        }
    }

    const onRowClick = (i, k) => {
        //setClickedRow(i);
        //setModal(!modal);
        console.log(i.address)
        history.push({pathname: "/main/manage/" + i.address });
    };

    // effect hook for updating data
    useEffect(() => {
        if (loading === false) {
            getConjureAssets();
        }
    }, [loading]);

    return (
        <div className="create">
            <div>
                <div className="mint">
                    <div className="header do-center">start creating</div>
                    <div onClick={connectWallet} className="connect-button">
                        Conjure Your Asset
                    </div>
                </div>
                <Table
                    className="create-table"
                    data={tableData}
                    heading={"Recently Created Assets"}
                    colors={{price: "#0499A5"}}
                    icon={RecentIco}
                    onClick={onRowClick}
                />
            </div>
            <Modal
                style={{width: "54%", top: "20%", left: "30%"}}
                toggleShow={() => {
                    setModal(!modal);
                }}
                show={modal}
            >
                <CreateModal setModal={setModal}/>
            </Modal>
        </div>
    );
};
export default Create;
