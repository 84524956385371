import React, {Component, useEffect, useState} from "react";
import {ManageTableData, AssetDetails} from "../../data";
import Table from "../subcomponents/table";
import "./airdrop.scss";
import {useLocation, useHistory, Redirect} from "react-router-dom";
import backIco from "../../assets/others/BackButton.svg";
import RecentIco from "../../assets/others/RecentEventIcon.svg";
import {DateTime} from "luxon";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";
import {addToast, PendingTx, SuccessfulTx, PendingIPFS, SuccessfulIPFS} from "../../hooks/useToast";
import getReceipt from "../../lib/getReceipt";
import {BigNumber} from '@ethersproject/bignumber';
import {formatEther, parseEther, formatUnits} from "@ethersproject/units";
import {
    CONJURE_FACTORY_ADDRESS,
    UNISWAPV2ORACLE_ADDRESS,
    CHAINLINK_OPTIONS,
    ETHUSD_CHAINLINK_ADDRESS, INFURA_NETWORK, INFURA_ID, MERKLE_ADDRESS
} from "../../constants";
import {useWeb3React} from "@web3-react/core";
import MERKLE_ABI from "../../constants/abi/merkle.json";
import Select, {components} from 'react-select'
import {InfuraProvider} from "@ethersproject/providers";
import {defaultAbiCoder, Interface} from "@ethersproject/abi";
import firebase from "firebase";

const encoder = defaultAbiCoder


const Button = ({name, onClick}) => {
    return <span className="small-button">{name}</span>;
};

const Input = ({onChange, placeholder, value, className}) => {
    return (
        <div className={className}>
            <input onChange={onChange} placeholder={placeholder} value={value}/>
        </div>
    );
};

const Airdrop = ({match}) => {
    const {account, library} = useWeb3React();

    var firebaseConfig = {
        apiKey: "AIzaSyBfd5WcZNRmdXWoAIKu9SXrhrwtfPH-Io4",
        authDomain: "alchemy-3175f.firebaseapp.com",
        databaseURL: "https://alchemy-3175f-default-rtdb.firebaseio.com",
        projectId: "alchemy-3175f",
        storageBucket: "alchemy-3175f.appspot.com",
        messagingSenderId: "449519711959",
        appId: "1:449519711959:web:0ded5a4e0b6a1e70ec2292",
        measurementId: "G-QHSY2SBC84"
    };


    if (firebase.apps.length === 0) {
        firebase.initializeApp(firebaseConfig);
    }


    async function checkClaim() {
        if (!account) {
            addToast({body: "Please Unlock Your Wallet", type: "error"});
            return
        }
        console.log('in')

        let checker = account
        console.log(checker)

        const res = await firebase.database().ref(`/claims/${checker}`).once('value');
        console.log(res.exists())

        console.log(res)

        addToast({body: "Getting your claim information. Please hold on a few seconds...", type: "info"});

        console.log(account)

        if (res === undefined || res.exists() === false)
        {
                //console.log('no claim');
                addToast({body: "Sorry no claim possible", type: "error"});
                return
        }
        else
        {
            try {
                const airdrop_contract = isAddress(MERKLE_ADDRESS) && !!MERKLE_ABI && !!library ? new Contract(MERKLE_ADDRESS, MERKLE_ABI, library.getSigner(account)) : undefined;
                const { hash } = await airdrop_contract.claim(res.val()['index'], checker, "0x01158e460913d00000", res.val()['proof']);
                await getReceipt(hash, library);
            } catch (e) {
                addToast({ body: e.message, type: "error" });
            }
        }
    }


    return (
        <div className="manage">
            <div className="stake">
                Click the button below and check if you can claim the airdrop of 20 $CNJ. If you are eligible a confirmation prompt of your wallet will appear. You just have to cover the gas to get your Airdrop!
            </div>
                <div className="details-section do-center">
                    <div>
                        <div className="connect-button" onClick={checkClaim}>
                            Check if you can claim!
                        </div>
                    </div>

                </div>
        </div>
    );
};
export default Airdrop;
