import React, { Component, useEffect, useState } from "react";
import { StakeData } from "../../data";
import "./stake.scss";
import DropdownIco from "../../assets/others/down_button.svg";
import CurrencyIco from "../../assets/others/LogoCircle@2x.png";
import { useLocation, useHistory, Redirect } from "react-router-dom";
import {AlchemyProvider, InfuraProvider} from "@ethersproject/providers";
import {useWeb3React} from "@web3-react/core";
import ERC20 from "../../constants/abi/erc20.json";
import POOL_ABI from "../../constants/abi/poollp.json";
import LINK from "../../constants/abi/chainlink.json";
import UNI_V2 from "../../constants/abi/univ2.json";
import useErc20Contract from "../../hooks/useErc20Contract";
import {Contract} from "@ethersproject/contracts";
import {isAddress} from "@ethersproject/address";
import {INFURA_ID, INFURA_NETWORK} from "../../constants";
import {formatEther} from "@ethersproject/units";
import {formatUnits} from "@ethersproject/units";

const StakeBlock = ({ data }) => {
  let history = useHistory();
    const {account, library} = useWeb3React();
    const erc20 = useErc20Contract(data.tokenAddress);
    const library_infura = new AlchemyProvider(INFURA_NETWORK, INFURA_ID);
    const contract = isAddress(data.poolAddress) && !!POOL_ABI && !!library_infura ? new Contract(data.poolAddress, POOL_ABI, library) : undefined;

    //set constants for later displaying
    const [first_loop, set_first_loop] = useState(0);
    const [poolapy, setpoolapy] = useState(0)
    const [unistaked, setunistaked] = useState(0)
    const [rewardleft, setrewardleft] = useState(0)
    const [poolshare, setpoolshare] = useState(0)

    // effect hook for updating data
    useEffect(() => {

        // update the ui elements
        async function updateUIStates() {

            {

                    const totalstake = await erc20.balanceOf(data.poolAddress)
                    setunistaked(formatEther(totalstake))


                    if (data.heading !== "Conjure (CNJ)")
                    {
                        const totalshare = await contract.totalAllocPoint()
                        const poollen = await contract.poolLength()

                        let i;
                        for (i = 0; i < poollen.toNumber(); i++) {
                            const lptok = await contract.lpToken(i)

                            if (lptok === data.tokenAddress) {
                                const allocpoint = await contract.poolInfo(i)

                                if (totalshare.toNumber() !== 0) {


                                    const poolsharetemp = allocpoint['allocPoint'].toNumber() / totalshare.toNumber() * 100
                                    setpoolshare(poolsharetemp.toFixed(2))

                                    const cnjcontract =
                                        isAddress("0x00a55375002f3cda400383f479e7cd57bad029a9") && !!ERC20 && !!library
                                            ? new Contract("0x00a55375002f3cda400383f479e7cd57bad029a9", ERC20, library)
                                            : undefined;

                                    const wethcontract =
                                        isAddress("0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2") && !!ERC20 && !!library
                                            ? new Contract("0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2", ERC20, library)
                                            : undefined;

                                    const linkcontract =
                                        isAddress("0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419") && !!LINK && !!library
                                            ? new Contract("0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419", LINK, library)
                                            : undefined;

                                    const amountcnj = await cnjcontract.balanceOf("0xd18d5ecd196f4a641fb5f61cdf2c4769b28d0ff0")
                                    const amountweth = await wethcontract.balanceOf("0xd18d5ecd196f4a641fb5f61cdf2c4769b28d0ff0")
                                    const ethusdprice = await linkcontract.latestAnswer()

                                    const ethvalue = formatEther(amountweth) * formatUnits(ethusdprice,8)
                                    const cnjvalue = ethvalue / formatEther(amountcnj)

                                    console.log('here')
                                    console.log(cnjvalue)

                                    const univ2 =
                                        isAddress(data.tokenAddress) && !!UNI_V2 && !!library
                                            ? new Contract(data.tokenAddress, UNI_V2, library)
                                            : undefined;

                                    const token0 = await univ2.token0();
                                    const token1 = await univ2.token1();
                                    const supply = formatEther(await univ2.totalSupply())

                                    const t0 =
                                        isAddress(token0) && !!ERC20 && !!library
                                            ? new Contract(token0, ERC20, library)
                                            : undefined;

                                    const t1 =
                                        isAddress(token1) && !!ERC20 && !!library
                                            ? new Contract(token1, ERC20, library)
                                            : undefined;

                                    const t0dec = await t0.decimals()
                                    const t1dec = await t1.decimals()

                                    const t0supply = formatUnits(await t0.balanceOf(data.tokenAddress), t0dec)
                                    const t1supply = formatUnits(await t1.balanceOf(data.tokenAddress), t1dec)

                                        // totalstake
                                    // price = pair.reserve0.times(2).div(pair.totalSupply).toNumber() * ethPrice;


                                    if (data.tokenAddress === "0x88E6eDe4266E5B97DCF70e67D49173e12a97985C") {

                                        const uniprice = (parseFloat(t1supply)+ parseFloat(t0supply) ) / (supply)
                                        console.log('test')
                                        console.log(uniprice)

                                        const apy =
                                            (((4600.0) * cnjvalue * 365.0) / (uniprice * formatEther(totalstake))) *
                                            100.0;
                                        setpoolapy(apy);
                                    }

                                    if (data.tokenAddress === "0xFc7b53024bbdB263dA0c1A0f4e255Aa3C169Df88") {
                                        const uniprice = (parseFloat(t1supply) * 46000  *2 ) / (supply)
                                        console.log(uniprice)
                                        const apy =
                                            (((277.0) * cnjvalue * 365.0) / (uniprice * formatEther(totalstake) )) *
                                            100.0;
                                        setpoolapy(apy);
                                    }

                                    if (data.tokenAddress === "0xcC8FC47494Fc01485720931A521478527078A65E") {

                                        let valuer = parseFloat(t1supply) * formatUnits(ethusdprice,8)

                                        const uniprice = (valuer * 2 ) / (supply)
                                        console.log(uniprice)
                                        const apy =
                                            (((138.0) * cnjvalue * 365.0) / (uniprice  * formatEther(totalstake))) *
                                            100.0;
                                        setpoolapy(apy);
                                    }

                                    //banana
                                    if (data.tokenAddress === "0x056EbdC492A49C0E2913c7AC14C2C46dBD3759FB") {

                                        const uniprice = (parseFloat(t1supply)+ parseFloat(t0supply) ) / (supply)
                                        console.log(uniprice)

                                        const apy =
                                            (((277.0) * cnjvalue * 365.0) / (uniprice * formatEther(totalstake))) *
                                            100.0;
                                        setpoolapy(apy);
                                    }



                                }

                            }

                        }


                    }

            }
        }

        // fix for updating after wallet login
        if (account && first_loop == 0)
        {
            set_first_loop(1);
            updateUIStates();
        }

        // schedule every 15 sec refresh
        const timer = setInterval(() => {
            if (account)
            {
                updateUIStates()
            }

        }, 10000);

        // clearing interval
        return () => clearInterval(timer);
    }, [ account, erc20, contract] );

  const goToDetails = ({ id, status }) => {
    history.push(`/main/stake/details/${id}/${status}`);
  };


  return (
    <div className="stake-block">
      <div className="header do-between">
        <div style={{ width: "12%" }}>
          <img src={data.icon || CurrencyIco} />
        </div>
        <span
          style={{
            width: "63%",
            color: "#CACBCD",
            fontWeight: "600",
            marginLeft: "10px",
          }}
        >
          {data.heading}
        </span>
        <span className="status" style={{ width: "20%" }}>
          {data.status}
        </span>
      </div>
      <div
        className="details"
        style={{ width: "65%", marginLeft: "10%", marginRight: "25%" }}
      >
        <div style={{ minHeight: "30px" }}>
          {data.status == "stake" && (
            <div>
              <div className="do-between" style={{ flexWrap: "wrap" }}>
                <span>Staked : {unistaked} </span>
              </div>
            </div>
          )}
        </div>

          {data.heading === "Conjure (CNJ)" ?
              <div>
                  <div> Earn CNJ Rewards for every mint in the protocol</div>
              </div>
          :
              <div>
                  <div> Pool Rewards&ensp; :&ensp; {poolshare}%</div>
                  <div> Pool APY&ensp; :&ensp; {poolapy.toFixed(2)}%</div>
              </div>
          }


        <div></div>
      </div>
      <div style={{ margin: "20px", marginLeft: "10%" }}>
        <div
          className="connect-button"
          onClick={() => goToDetails({ id: data.id, status: data.status })}
        >
          {data.status == "finished" ? "view" : "stake"}
        </div>
      </div>
    </div>
  );
};
const Stake = () => {
  const [stakeData, setStakeData] = useState(StakeData);

  useEffect(() => {
    //call api for stakeData details
  });

  const connectWallet = () => {};

  return (
    <div className="stake">
      {stakeData.map((i, k) => {
        return <StakeBlock data={i} />;
      })}
    </div>
  );
};
export default Stake;
