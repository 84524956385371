import React, { Component, useState } from "react";
import onClickOutside from "react-onclickoutside";

function ClickOutside({
  type,
  options,
  onChange,
  setOpened,
  opened,
  children,
}) {
  ClickOutside.handleClickOutside = () => {
    setOpened(false);
  };

  return children;
}

const clickOutsideConfig = {
  handleClickOutside: () => ClickOutside.handleClickOutside,
};

export default onClickOutside(ClickOutside, clickOutsideConfig);
