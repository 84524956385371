import React, { Component, useState } from "react";
import {
  RecentlyCreatedAssetsData,
  AssetData,
  CreateButtons,
} from "../../data";
import DropdownModal from "../subcomponents/dropdownModal";
import "./create.scss";
import DropdownIco from "../../assets/others/down_button.svg";
import Table from "../subcomponents/table";
import Modal from "../subcomponents/modal";
import CloseIco from "../../assets/others/CancelButton.svg";
import PlusIco from "../../assets/others/plus.svg";
import MinusIco from "../../assets/others/minus.svg";
import {isAddress} from "@ethersproject/address";
import {Contract} from "@ethersproject/contracts";
import {addToast, PendingTx, SuccessfulTx, PendingIPFS, SuccessfulIPFS} from "../../hooks/useToast";
import getReceipt from "../../lib/getReceipt";
import {BigNumber} from '@ethersproject/bignumber';
import {formatEther, parseEther, formatUnits} from "@ethersproject/units";
import {
  CONJURE_FACTORY_ADDRESS,
  UNISWAPV2ORACLE_ADDRESS,
  CHAINLINK_OPTIONS,
  ETHUSD_CHAINLINK_ADDRESS,
  DIA_OPTIONS,
  DIA_ORACLE,
  OOF_OPTIONS
} from "../../constants";
import {useWeb3React} from "@web3-react/core";
import ABI from "../../constants/abi/ConjureFactory.json";
import Select, {components} from 'react-select'
import { useHistory } from "react-router";
const {defaultAbiCoder} = require("@ethersproject/abi");
const encoder = defaultAbiCoder


const dropdownOptionsB = [
  {
    label: "Single Asset",
    id: 0,
  },
  {
    label: "Basket Asset",
    id: 1,
  },
  {
    label: "MarketCap Asset",
    id: 2,
  },
  {
    label: "Sqrt MarketCap Asset",
    id: 2,
  },
];

const SectionInputs = {
  link: {
    data: [
      { width: "100%", placeholder: "Select Price feed", param: "priceFeed" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
      { width: "100%", placeholder: "Oracle Weight", param: "oracleWeight" },
    ],
    width: "100%",
  },
  uni: {
    data: [
      { width: "100%", placeholder: "Token Address", param: "tokenAddress" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
      { width: "100%", placeholder: "Oracle Weight", param: "oracleWeight" },
    ],
    width: "100%",
  },
  custom: {
    data: [
      { width: "25%", placeholder: "Address", param: "address" },
      { width: "25%", placeholder: "Signature", param: "signature" },
      { width: "25%", placeholder: "Value", param: "value" },
      { width: "25%", placeholder: "Call Data", param: "callData" },
      { width: "25%", placeholder: "Decimals", param: "decimals" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
      { width: "25%", placeholder: "Oracle Weight", param: "oracleWeight" },
    ],
    width: "100%",
  },
  conjure: {
    data: [
      { width: "40%", placeholder: "Conjure Address", param: "address" },
      { width: "40%", placeholder: "Oracle Weight", param: "oracleWeight" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
    ],
    width: "100%",
  },
  oof: {
    data: [
      { width: "100%", placeholder: "Select OOF Price feed", param: "priceFeed" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
      { width: "100%", placeholder: "Oracle Weight", param: "oracleWeight" },
    ],
    width: "100%",
  },
  dia: {
    data: [
      { width: "100%", placeholder: "Select Price feed", param: "priceFeedDia" },
      { width: "100%", placeholder: "Underlying Token for MCap", param: "inputToken" },
      { width: "100%", placeholder: "Oracle Weight", param: "oracleWeight" },
    ],
    width: "100%",
  },
};

const SectionBlock = ({ data, assetType, onCancel, onChange, index, selected }) => {
  const selectedData = SectionInputs[selected];
  let temp_data = selectedData;

  console.log(assetType)
  console.log(selectedData.data)
  console.log(temp_data.data)
  console.log(selected)

  let i;
  for (i=0;i < temp_data.data.length; i++) {
    console.log(temp_data.data[i])

    if (assetType === 0) {
      if (temp_data.data[i].param === "oracleWeight") {
        console.log('weight')
      }
      else if (temp_data.data[i].param === "inputToken") {
        console.log('input')
      }
    }

  }


  return (
    <div className="section-block" style={{ width: temp_data.width }}>
      <span onClick={() => onChange({ index })}>
        <img src={CloseIco} />
      </span>
      {temp_data.data.map((i, k) => {
        return (
            i.placeholder === "Select Price feed" ?

                selected === 'link' ?

                  <div key={k} className="item item2" style={{ width: i.width }}>
                    <div style={{width: '100%'}}>
                    <Select
                        options={CHAINLINK_OPTIONS}
                        style={{ width: "100%" }}
                        onChange={(e) =>
                            onChange({
                              index,
                              value: { ...data, [i.param]: e.value },
                            })
                        }
                    />
                    </div>
                  </div>


                    :

                    <div key={k} className="item item2" style={{ width: i.width }}>
                      <div style={{width: '100%'}}>
                        <Select
                            options={DIA_OPTIONS}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                                onChange({
                                  index,
                                  value: { ...data, [i.param]: e.value },
                                })
                            }
                        />
                      </div>
                    </div>

            :

                i.placeholder === "Select OOF Price feed" ?

                    <div key={k} className="item item2" style={{ width: i.width }}>
                      <div style={{width: '100%'}}>
                        <Select
                            options={OOF_OPTIONS}
                            style={{ width: "100%" }}
                            onChange={(e) =>
                                onChange({
                                  index,
                                  value: { ...data, [i.param]: e.value },
                                })
                            }
                        />
                      </div>
                    </div>

                    :

                i.param === "oracleWeight" && assetType === 0 ?
                    ""
                    :
                    i.param === "inputToken" && assetType < 2 ?
                        ""
                        :
                        i.param === "inputToken" && selected === "uni" ?
                            ""
                            :

                  <div key={k} className="item item2" style={{ width: i.width }}>
                    <input
                        value={data[i.param] || ""}
                        placeholder={i.placeholder}
                        onChange={(e) =>
                            onChange({
                              index,
                              value: { ...data, [i.param]: e.target.value },
                            })
                        }
                    />
                  </div>


        );
      })}
    </div>
  );
};

const Button = ({ data, onClick }) => {
  return (
    <span className="small-button" onClick={() => onClick(data.id)}>
      {data.name}
    </span>
  );
};
const CreateModal = ({ setModal }) => {
  const [inputVal, setInputVal] = useState("");
  const [priceDevisor, setPriceDevisor] = useState("");
  const [selected, setSelected] = useState("");
  const [sectionBlockData, setSectionBlockData] = useState([]);
  const [assetData, setAssetData] = useState(AssetData);
  const [buttons, setButtons] = useState(CreateButtons);
  const [dropdownB, setDropdownB] = useState(false);
  let history = useHistory();

  const {account, library} = useWeb3React();

  const getDetails = () => {
    setModal(false);
  };

  const onChange = ({ index, value }) => {
    let arr = sectionBlockData;

    if (value) {
      arr.splice(index, 1, {
        ...sectionBlockData[index],
        ...value,
      });
    } else arr.splice(index, 1);

    console.log(value, "LKkkkkkkkkkkkkk", arr);
    setSectionBlockData([...arr]);
  };

  const increment = (val, type) => {

    console.log(type)

    if (type === "mintingFee") {
      if (val.mintingFee >= 2.5) {
        setAssetData({ ...assetData, ...{mintingFee: 2.5} });
      }
      else if (val.mintingFee <= 0){
        setAssetData({ ...assetData, ...{mintingFee: 0.0} });
      }
      else {
        setAssetData({ ...assetData, ...val });
      }
    } else {
      if (val.cRatio >= 1000) {
        setAssetData({ ...assetData, ...{cRatio: 1000} });
      }
      else if (val.cRatio <= 120){
        setAssetData({ ...assetData, ...{cRatio: 120} });
      }
      else {
        setAssetData({ ...assetData, ...val });
      }
    }

    //setAssetData({ ...assetData, ...val });
  };

  function handleChangeFee(event) {
    const values = event.target.value;

    try {
      let parser = parseFloat(values)

      if (parser <= 0) {
        setAssetData({
          ...assetData,
          mintingFee: 0,
        })
      }
      else if (parser > 2.5) {
        setAssetData({
          ...assetData,
          mintingFee: 2.5,
        })
      }
      else {
        setAssetData({
          ...assetData,
          mintingFee: values,
        })
      }

    } catch(e) {
      setAssetData({
        ...assetData,
        mintingFee: 0,
      })
    }
  }

  function handleChangeCRatio(event) {
    const values = event.target.value;

    try {
      let parser = parseFloat(values)

      if (parser <= 0) {
        setAssetData({
          ...assetData,
          cRatio: 120,
        })
      }
      else if (parser > 1000) {
        setAssetData({
          ...assetData,
          cRatio: 1000,
        })
      }
      else {
        setAssetData({
          ...assetData,
          cRatio: values,
        })
      }

    } catch(e) {
      setAssetData({
        ...assetData,
        cRatio: 120,
      })
    }
  }

  // mint Conjure
  const callConjureFactory = async () => {

    console.log('in');

    console.log(assetData)
    console.log(sectionBlockData)

    let i;
    let addresses = [];
    let values = [];
    let signatures = [];
    let decimals = [];
    let weights = [];
    let calldata = [];
    let types = [];
    let sumweights = 0;
    let inputtokens = [];


    for (i=0;i < sectionBlockData.length; i++) {

      if (sectionBlockData[i].name === "uni") {
        //addresses.push(sectionBlockData[i].tokenAddress)
        addresses.push(UNISWAPV2ORACLE_ADDRESS)
        values.push(0)
        signatures.push("computeAverageTokenPrice(address,uint256,uint256)")
        decimals.push(18)

        let parameters = encoder.encode(
            ["address","uint256","uint256"],
            [sectionBlockData[i].tokenAddress,0, 60*60*24*7]
        )

        calldata.push(parameters)
        types.push(1)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].tokenAddress)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }

      if (sectionBlockData[i].name === "link") {
        addresses.push(sectionBlockData[i].priceFeed)
        values.push(0)
        signatures.push(0)
        decimals.push(8)
        calldata.push(0x00)
        types.push(0)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].inputToken)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }

      if (sectionBlockData[i].name === "conjure") {
        addresses.push(sectionBlockData[i].address)
        values.push(0)
        signatures.push("getLatestPrice()")
        decimals.push(18)
        calldata.push(0x00)
        types.push(2)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].inputToken)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }

      if (sectionBlockData[i].name === "custom") {
        addresses.push(sectionBlockData[i].address)
        values.push(sectionBlockData[i].value)
        signatures.push(sectionBlockData[i].signature)
        decimals.push(sectionBlockData[i].decimals)
        calldata.push(sectionBlockData[i].callData)
        sumweights = sumweights + sectionBlockData[i].oracleWeight
        types.push(2)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].inputToken)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }

      if (sectionBlockData[i].name === "dia") {
        addresses.push(DIA_ORACLE)
        values.push(0)
        signatures.push("getCoinInfo(string)")
        decimals.push(5)

        let parameters = encoder.encode(
            ["string"],
            [sectionBlockData[i].priceFeedDia]
        )

        calldata.push(parameters)
        sumweights = sumweights + sectionBlockData[i].oracleWeight
        types.push(2)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].inputToken)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }

      if (sectionBlockData[i].name === "oof") {
        addresses.push("0x00f0feed50DcDF57b4f1B532E8f5e7f291E0C84b")
        values.push(0)
        signatures.push("getFeed(uint256)")
        decimals.push(18)

        const calldatas =
            defaultAbiCoder.encode(['uint256'], [sectionBlockData[i].priceFeed])
        ;

        calldata.push(calldatas)
        types.push(2)

        if (assetData.assetType.id === 1) {
          weights.push(sectionBlockData[i].oracleWeight)
          sumweights = sumweights + parseInt(sectionBlockData[i].oracleWeight)
        } else {
          weights.push(0)
        }

        if (assetData.assetType.id === 2 || assetData.assetType.id === 3) {
          inputtokens.push(sectionBlockData[i].inputToken)
        } else {
          inputtokens.push("0x0000000000000000000000000000000000000000");
        }
      }
    }


    // check if weights are accurate
    if (sumweights !== 100 && assetData.assetType.id === 1)
    {
      addToast({body: "All weights must add up to 100", type: "error"});
      return;
    }

    if (assetData.mintingFee < 0)
    {
      addToast({body: "Fee cant be negative", type: "error"});
      return;
    }
    if (assetData.mintingFee > 2.5)
    {
      addToast({body: "Fee cant be more than 2.5%", type: "error"});
      return;
    }

    if (assetData.assetType.id === 0)
    {
      assetData.divisor = 1;
    }

    if (assetData.divisor === "")
    {
      assetData.divisor = 1;
    }

    if (assetData.divisor <= 0)
    {
      addToast({body: "Divisor cant be 0 or lower", type: "error"});
      return;
    }

    console.log(assetData.divisor)

    const contract = isAddress(CONJURE_FACTORY_ADDRESS) && !!ABI && !!library ? new Contract(CONJURE_FACTORY_ADDRESS, ABI, library.getSigner(account)) : undefined;

    try {

      contract.once("NewConjure", (address, event) => {
        console.log("Contract event");
        console.log(address);
        history.push({pathname: "/main/manage/" + address });
      });


      const {hash} = await contract.conjureMint(
          [types,values,weights,decimals],
          calldata,
          signatures,
          [addresses,inputtokens],
          [[assetData.divisor,assetData.assetType.id], [assetData.mintingFee*100,parseEther(assetData.cRatio.toString())]],
          [account,ETHUSD_CHAINLINK_ADDRESS],
          [assetData.name, assetData.symbol],
          assetData.inverseAsset
      );
      await getReceipt(hash, library);


    } catch (e) {
      addToast({body: e.message, type: "error"});
    }
  }

  return (
    <div className="create-modal do-center">
      <div className="header do-center">Conjure your Asset</div>
      <div className="item">
        <input
            placeholder="Name"
            value={assetData.name}
            onChange={(e) =>
                setAssetData({
                  ...assetData,
                  name: e.target.value,
                })
            }
        />
      </div>
      <div className="item">
        <input
            placeholder="Symbol"
            value={assetData.symbol}
            onChange={(e) =>
                setAssetData({
                  ...assetData,
                  symbol: e.target.value,
                })
            }
        />
      </div>
      <div className="section">
        <div className="section-data">
          <div style={{ position: "relative" }}>
            <span>Asset Type</span>
            {/* <input
              type="text"
              defaultValue={assetData.assetType}
              value={assetData.assetType}
              onChange={(e) =>
                setAssetData({
                  ...assetData,
                  assetType: e.target.value,
                })
              }
            /> */}
            <div
              onClick={() => setDropdownB(true)}
              style={{ cursor: "pointer", textAlign: "right" }}
            >
              <span style={{ color: "#cacbcd" }}>{assetData.assetType.label}</span>
              <img
                src={DropdownIco}
                style={{ width: "14px", marginLeft: "10px" }}
              />
            </div>
            {dropdownB && (
              <DropdownModal
                extraClass={"assetType"}
                options={dropdownOptionsB}
                opened={dropdownB}
                onChange={(i) =>
                  setAssetData({ ...assetData, assetType: i })
                }
                setOpened={setDropdownB}
              />
            )}
          </div>

          <div>
            <span>Inverse Asset</span>
            <label className="checkbox bounce">
              <input
                type="checkbox"
                // defaultValue={assetData.inverseAsset}
                checked={assetData.inverseAsset}
                value={assetData.inverseAsset}
                onChange={(e) =>
                  setAssetData({
                    ...assetData,
                    inverseAsset: e.target.checked,
                  })
                }
              />
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
              </svg>
            </label>
          </div>
          <div>
            <span>Minting Fee (%)</span>
            <div className="input-icon">
              <input
                type="number"
                min="0"
                step="0.1"
                max="2.5"

                // defaultValue={assetData.mintingFee}
                value={assetData.mintingFee}
                onChange={e => handleChangeFee( e)}
              />
              <div>
                <img
                    src={PlusIco}
                    onClick={() =>
                        increment({ mintingFee: + assetData.mintingFee + 0.1 }, "mintingFee")
                    }
                />
                <img
                    src={MinusIco}
                    onClick={() =>
                        increment({ mintingFee: + assetData.mintingFee - 0.1 }, "mintingFee")
                    }
                />
              </div>
            </div>
          </div>
          <div>
            <span>C- Ratio (%)</span>
            <div className="input-icon">
              <input
                type="number"
                step="100"
                min="120"
                max="1000"

                onChange={e => handleChangeCRatio( e)}
                // defaultValue={assetData.cRatio}
                value={assetData.cRatio}

              />
              <div>
                <img
                    src={PlusIco}
                    onClick={() => increment({ cRatio: + assetData.cRatio + 1 }, "cRatio")}
                />
                <img
                    src={MinusIco}
                    onClick={() => increment({ cRatio: + assetData.cRatio - 1 }, "cRatio")}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "20px" }}>
          <div className="heading">Add Your Price Sources</div>
          <div className="section-a do-between">
            {sectionBlockData[0] &&
              sectionBlockData.map((i, k) => (
                <SectionBlock
                  index={k}
                  onChange={onChange}
                  selected={i.name}
                  assetType={assetData.assetType.id}
                  data={i}
                  allData={sectionBlockData}
                />
              ))}
          </div>
          <div className="button-class">
            {buttons.map((i, k) => (
              <Button
                data={i}
                key={k}
                onClick={(val) => {
                  setSectionBlockData([...sectionBlockData, { name: val }]);
                }}
              />
            ))}
          </div>
          <div className="bottom-div do-between">

            { assetData.assetType && assetData.assetType.id !== 0 ?
                <div className="item item4">
                  <input
                      placeholder="Enter Price Divisor"
                      value={assetData.divisor}
                      onChange={(e) =>
                          setAssetData({
                            ...assetData,
                            divisor: e.target.value})
                      }
                  />
                </div>
            :

            ""
            }

            <div className="connect-button asset-button" onClick={callConjureFactory}>CONJURE NEW ASSET</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateModal;
