import React, { Component, useState } from "react";
import "./dropdownModal.scss";
import onClickOutside from "react-onclickoutside";

function NotificationModal({
  type,
  options,
  onChange,
  setOpened,
  opened,
  children,
}) {
  NotificationModal.handleClickOutside = () => {
    setTimeout(() => {
      setOpened(false);
    }, 200);
  };

  const toggle = (val) => {
    onChange(val);
    setOpened(false);
  };

  return (
    // <div className="dropdown-modal">
    opened ? children : null
    // </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => NotificationModal.handleClickOutside,
};

export default onClickOutside(NotificationModal, clickOutsideConfig);
